body {
  margin: 0;
  font-family: rodchenko;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url( 'images/dark-background.jpg'); */
  background: url('images/warcry-bg.jpg');
  color: black;
  font-size: 1.5rem;
}

button.red {
  font-family: rodchenko cond,rodchenko-cond,sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  box-shadow: 0 3px 32px 0 rgb(0 0 0 / 50%);
  background-color: #b2221a;
  transition: background-color 0.2s;
    padding: 1.5rem;
    margin-top: 3rem;
}

button { 
  background-color: #a06b1b;
  background-image: url(images/button-background.jpg);
  background-size: cover;
  min-height: 70px;
  margin: 10px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: rodchenko, sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    color: rgb(62, 62, 62);
    padding: 10px;
}


.flip-card {
  background-color: transparent;
  min-height: 380px;
  max-width: 90vw;
  height: 30vw;
  max-height: 420px;
  margin-bottom: 5vw;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  transform: scale(1,1);
  transition: transform 0.2s;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform-origin: center;
}

.flip-card.shuffled .flip-card-inner{
  transition: none;
}

.flip-card-inner img {
  border-radius: 1.5em;
  width: 100%;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flipped .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card.shuffled {
  transform: scale(1.2,1.2);
  transition: none
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card .shuffle-button {
  position: absolute;
  top: 0em;
  right: 0.3em;
  border-radius: 50%;
  width: 2.5em;
    height: 2.5em;
  background: rgba(0,0,0,0.2);
  border: 1px solid gray;
  cursor: pointer;
  filter: grayscale();
  font-size: 1.8rem;
  padding-top: 0.5em;
  padding-left: 0.38em;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: transparent;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: transparent;
  color: white;
  transform: rotateX(180deg);
}